import $ from 'jquery';

class Filters {

  static setupAll() {
    $('[data-filter]').each((index, element) => new Filters(element))
  }

  constructor(selector) {
    let array = []
    const filter = this.getParams('filter')

    this.container = $(selector)

    if(filter){
      array = filter.split(',');
      $('[data-filter="todos"]').removeClass('active');
      array.map((classe, i) => { 
        $(`.grid-item`).hide();
        $(`.grid-item.${classe}`).show();
        $(`[data-filter=${classe}]`).addClass('active');
      });
    }

    $('[data-filter]').click((e) => this.onClick(e, array))
  }

	getParams(key){
    var params = {};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi,
    function(str,key,value){
      params[key] = value
    });
    return key?params[key]:params;
  }

  onClick(e, array) {
    const idx =  $(e.currentTarget).index();
    const data = e.currentTarget.getAttribute('data-filter')
    const active = e.currentTarget.getAttribute('class', 'active')

    if(data != 'todos'){
      this.container.removeClass('active');
      $(e.currentTarget).addClass('active');

      array.push( data );
      history.pushState( {}, '', '?filter=' + array );

      if(active) {
        $(e.currentTarget).removeClass('active');
        
        array = $.grep(array, (value) => { return value != data });
        history.pushState( {}, '', '?filter=' + array );
      }

    } else if (data === 'todos') {
      this.container.removeClass('active');
      $(e.currentTarget).addClass('active');

      array = [];
      history.pushState( {}, '', '?filter=' + array);
    }
    
    // Reload
    location.reload();
  
  }
}

export default Filters