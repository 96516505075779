import $ from 'jquery';

// Load more on click
	const page = Number($('.grid-item').attr('data-page'));
	const amount = Number($('.grid-item').attr('data-page-amount'));

	if(page === amount) {
		$('.btn-load').hide();
	}

	function getParams(key){
    var params = {};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi,
    function(str,key,value){
      params[key] = value
    });
    return key?params[key]:params;
  }

	$('.more').on('click', function(e) {
		console.log(e)
	  const that = $(this);
	  const page = $(this).data('page');
	  const cat = $(this).data('category');
	  const newPage = page + 1;
	  const ajaxurl = that.data('url');
	  const newCat = getParams('filter');

	  $('.dots').addClass('loading');
	  that.addClass('hide');

		$.ajax({
				url: ajaxurl,
				type: 'POST',
				data: {
				page: page,
				action: 'ajax_script_load_more'
			},

			error: function(response) {
				console.log(response);
			},

			success: function(response) {
				if (response === 0) {
					$('.dots').removeClass('loading');
					that.addClass('hide');

				} else {

					if(page === (amount - 1)) {
						$('.btn-load').hide();
					}

					that.data('page', newPage);
					that.removeClass('hide');

					$('.dots').removeClass('loading');
					$('.articles').append(response.substr(response.length - 1, 1) === '0' ? response.substr(0, response.length - 1) : response);
				}
			}
		});
	});