import $ from 'jquery';
import './js/ajax.js';
import './scss/style.scss';
import slick from 'slick-carousel';
import Filters from './js/filters';
import Modal from './js/modal';

Filters.setupAll();
Modal.setupAll();

$('.menu-btn').click(function() {
  const has = $(this).hasClass('open');
  if (has) {
    $(this).removeClass('open');
    $('.menu-list').removeClass('open');
  } else {
    $(this).addClass('open');
    $('.menu-list').addClass('open');
  }
});

$('[data-menu="link"]').each(function(e, i){
  const classItem = $(this).attr('data-name')
  const dataItem = $(this).attr('data-link')
  if(classItem == dataItem) {
    $(this).addClass('current')
  }
});

// Scroll and fix side social bar
var has = $('main').hasClass('single');
if (has) {
  $(window).scroll(function(){
    const elPosition = $( '.single article .text' ).offset().top;
    const containerHeight = $( '.single article .text' ).height();
    const elHeight = $( '.share' ).height();
    const windowPosition = $( this ).scrollTop();
    const elLimit = containerHeight - elHeight + elPosition - 100; // 20 - element top

    if ( windowPosition > elPosition && windowPosition < elLimit ) {
      $('.share').addClass('fixed');
      $('.share').removeClass('absolute');
    } 
    else if ( windowPosition >= elLimit ) {
      $('.share').addClass('absolute');
      $('.share').removeClass('fixed');
    } 
    else {
      $('.share').removeClass('fixed');
      $('.share').removeClass('absolute');
    }
  });
}
