import $ from 'jquery';

class Modal {
  static setupAll() {
    $('.slider').each((index, element) => new Modal(element))
  }

  constructor(selector) {
    this.container = $(selector)
    this.slides = this.container.find('.item')
    this.modal = this.container.parent()

    this.current = 0

    $('.next').click((e) => this.next())
    $('.prev').click((e) => this.prev())
    $('.grid-item').click((e) => this.clickItem(e))
    $('.close').click((e) => this.close())
  }

  next() {
    this.change(this.current + 1)
  }

  prev() {
    this.change(this.current - 1)
  }

  close() {
    this.modal.hide()
  }

  clickItem(e) {
    const index = $(e.currentTarget).index()
    this.change(index)
    $(`[index=${index}]`).addClass('current')
    this.modal.show()
  }

  change(index) {
    const max = this.slides.length - 1
    if (index > max) {
      index = 0
    }
    if (index < 0) {
      index = max
    }
    this.onCompleteChange(index)
  }

  onCompleteChange(index) {
    if (this.current === index) {
      return
    }
    this.slides.eq(this.current).removeClass('current')
    this.current = index
    this.slides.eq(this.current).addClass('current')
  }
}
export default Modal
